var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.box)?_c('div',{staticClass:"index"},[_c('div',{class:[
      'inner',
      { 'hide' : _vm.loginError || _vm.loginSuccess }
    ]},[_c('div',{staticClass:"animated-on-change"},[(_vm.showQrcodeTest)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.qrcodeTest),expression:"qrcodeTest"}],staticClass:"test-input-terminal",attrs:{"type":"text","placeholder":'QR Code'},domProps:{"value":(_vm.qrcodeTest)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onEnter.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.qrcodeTest=$event.target.value}}}):_vm._e(),_c('h1',{staticClass:"headline",domProps:{"innerHTML":_vm._s(_vm.$t('outdoor.index.headline'))}}),_c('box-status',{attrs:{"boxOpened":_vm.boxOpened,"fakeHours":_vm.fakeHours,"box":_vm.box}}),(_vm.boxOpenedCatchError.isError)?_c('box-error',{attrs:{"boxOpenedCatchError":_vm.boxOpenedCatchError}}):_vm._e(),(_vm.boxOpened.isOpen && (_vm.box.hasAppCheckin === 1 || _vm.box.hasSmsCheckin === 1))?_c('div',{staticClass:"qr-codes"},[_c('div',{staticClass:"qr-code download"},[_c('div',{staticClass:"inner"},[_c('img',{attrs:{"src":(_vm.rootUrl + "/qr/?d=http://anybox-www.int5.syreta.com/?boxID=" + (_vm.box.id) + "&locale=" + _vm.boxLang + "&fg=353635")}})])])]):_vm._e()],1),_c('div',{class:[
        'scan',
        { 'hide' : !_vm.boxOpened.isOpen
        || _vm.boxOpenedCatchError.isError
        || _vm.loginError
        || _vm.loginSuccess }
      ]},[_c('box-bottom-info',{attrs:{"boxOpened":_vm.boxOpened,"box":_vm.box}})],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }